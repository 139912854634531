<template>
  <div class="m-2">
    <arq-previews-page />

    <h1>Instalación del medidor</h1>
    <form class="col-10 col-md-8 mx-auto my-4">
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <div v-if="f.key.includes('phone')">
              <b-form-input
                :id="f.key"
                v-model="phone"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <div v-if="f.key.includes('email')">
              <b-form-input
                :id="f.key"
                v-model="email"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
          </div>
          <div v-if="f.type === 'select-contract'">
            <div v-if="contratos.length > 0">
              <b-form-select
                v-model.number="data.contrato"
                value-field="idProducto"
                text-field="descservicio"
                :options="contratos"
                @input="getOptionServices(data[f.key])"
              />
            </div>
            <div v-else>
              <b-form-input
                :id="f.key"
                v-model.number="data[f.key]"
                :required="f.required"
                :disabled="f.disabled"
                :name="f.key"
                type="number"
                @input="getOptionServices(data[f.key])"
              />
            </div>
          </div>
          <div v-if="f.type === 'select-cuota'">
            <b-form-select
              :id="f.key"
              v-model.number="cuotaValue"
              :options="cuotas"
            />
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
            @input="uploadImage()"
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <div class="col-12">
          <b-button
            :disabled="disabledButton"
            variant="primary"
            class="
              mt-3
              d-flex
              text-center
              w-100
              mr-auto
              ml-auto
              justify-content-center
            "
            @click="confirm()"
          >
            <div v-if="loading">
              <b-spinner
                small
                class="mr-1"
                label="Small Spinner"
              />
            </div>
            Confirmar
          </b-button>
        </div>
      </div>
    </form>
    <b-modal
      id="modal-confirm-process"
      title="Tramite"
      ok-only
    >
      <div v-if="!response.message">
        <p class="my-4">
          <b-spinner
            variant="success"
            label="Spinning"
          />
          Enviando solicitud!
        </p>
      </div>
      <div v-else>
        <center>
          <b-alert
            :variant="response.variant"
            show
          >
            {{ response.message }}
          </b-alert>
          <div>
            <center>
              <a
                v-if="response.okAccion"
                href="/portal-register"
              >
                Registrarse
              </a>
            </center>
            <center>
              <button
                class="bg-primary text-white mx-auto mr-auto btn mt-2"
                style="margin-top: 25px"
                @click.prevent="$bvModal.hide('modal-confirm-process')"
              >
                Cerrar
              </button>
            </center>
          </div>
        </center>
        <ArqPdf
          v-if="response.base64"
          :no-print="true"
          :src="response.base64"
        />
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          hidden
          @click="ok()"
        >
          OK
        </b-button>
        <b-button
          hidden
          @click="cancel()"
        >
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button
          hidden
          @click="hide('forget')"
        >
          Forget it
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-actualizar-datos-personales"
      title="Actualizar datos personales"
      hide-footer
    >
      <!-- ok-title="Confirmar"
      cancel-title="Cancelar"
      @ok="handleOk" -->
      <div v-if="!responseUpdatePersonal.message">
        <b-alert
          variant="danger"
          show
        >
          Necesita actualizar los datos personales
        </b-alert>
        <form
          ref="formActualizarDatosPersonales"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label="Telefono"
            label-for="phone-input"
            invalid-feedback="Telefono es requerido"
            :state="phoneState"
          >
            <b-form-input
              id="phone-input"
              v-model="dataPersonal.celularespo"
              max-length="10"
              min-length="10"
              required
              type="number"
              :state="phoneState"
            />
          </b-form-group>
          <b-form-group
            label="Correo"
            label-for="email-input"
            invalid-feedback="Email es requerido"
            :state="emailState"
          >
            <b-form-input
              id="email-input"
              v-model="dataPersonal.emailresposerv"
              required
              type="email"
              :state="emailState"
            /></b-form-group>
        </form>
        <button
          class="bg-primary text-white m-auto mr-auto btn"
          style="position: relative; margin-left: 12em !important"
          @click.prevent="handleOk"
        >
          Confirmar
        </button>
      </div>
      <div v-else>
        <b-alert
          :variant="responseUpdatePersonal.variant"
          show
        >
          a {{ responseUpdatePersonal.message }}
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { BSpinner } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      form: [
        {
          label: 'Contrato',
          key: 'contrato',
          type: 'select-contract',
          required: true,
        },
        {
          label: 'Celular',
          key: 'phone',
          type: 'number',
          disabled: true,
          required: true,
        },
        {
          label: 'Correo',
          key: 'email',
          type: 'text',
          disabled: true,
          required: true,
        },
        {
          label: '¿Como desea pagar el medidor?',
          key: 'observacion',
          type: 'select-cuota',
          required: true,
        },
      ],
      data: {},
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      optionsServices: [],
      idService: 0,
      imageSrc: '',
      fileType: '',
      fileName: '',
      disabledButton: false,
      cuotas: [
        {
          value: '1',
          text: '1 cuota',
        },
        {
          value: '6',
          text: '6 cuotas',
        },
        {
          value: '12',
          text: '12 cuotas',
        },
        {
          value: '18',
          text: '18 cuotas',
        },
      ],
      phone: '',
      email: '',
      cuotaValue: '',
      process: {},
      responseUpdatePersonal: {},
      phoneState: false,
      emailState: false,
      identificacionState: false,
      response: {},
      dataPersonal: {},
    }
  },
  computed: {
    ...mapState('client', ['contratos']),
    observer() {
      const { contrato } = this.data
      if (contrato) {
        this.getOptionServices(contrato)
      }
      return null
    },
  },
  methods: {
    getContract(id) {
      this.contratos = []
      axios.get(`/client/contracts/${1}/${id}`).then(res => {
        this.contratos = res.data.list
      })
    },
    getOptionServices(contrato) {
      this.phone = ''
      this.email = ''
      if (contrato) {
        axios
          .get(`/app/services/fromContract?idContract=${contrato}`)
          .then(res => {
            if (res.data.code === 0) {
              this.optionsServices = res.data.list
              this.idService = res.data.object.idProductoDefault
              this.phone = res.data.object.celularespo
              this.email = res.data.object.emailresposerv
            } else {
              this.optionsServices = []
            }
          })
      }
    },
    created() {
      this.data.identification = this.$store.state.client.client.identificacion
      this.data.email = this.$store.state.client.client.emailresposerv
      this.data.phone = this.$store.state.client.client.celularespo
    },
    handleOk(bvModalEvt) {
      this.data.identification = this.$store.state.client.client.identificacion
      bvModalEvt.preventDefault()
      let validate = false
      // if (!this.dataPersonal.identificacion) {
      //   validate = this.checkFormValidity()
      //   if (!validate) {
      //     // console.log('blos')
      //     return false
      //   }
      //   return false
      // }
      if (!this.dataPersonal.celularespo && !this.dataPersonal.emailresposer) {
        validate = this.checkFormValidity()
        if (!validate) {
          return false
        }
      }
      validate = this.checkFormValidity()
      this.phoneState = true
      this.emailState = true
      this.dataPersonal.identificacion = this.data.identification
      axios
        .post('/app/person/update', this.dataPersonal)
        .then(res => {
          if (res.data.code === 0) {
            this.responseUpdatePersonal.message = 'Sus datos han sido actualizados exitosamente.'
            this.responseUpdatePersonal.variant = 'success'
            this.data = {}
          } else {
            this.responseUpdatePersonal.message = 'Error al intentar actualizar los datos.'
            this.responseUpdatePersonal.variant = 'danger'
          }
        })
        .finally(() => {
          this.dataPersonal = {}
        })
      this.$nextTick(() => {
        this.$bvModal.hide('modal-actualizar-datos-personales')
      })
      return true
      // Trigger submit handler
    },
    checkFormValidity() {
      const valid = this.$refs.formActualizarDatosPersonales.checkValidity()
      return valid
    },
    openActualizacionDatos() {
      this.$bvModal.show('modal-actualizar-datos-personales')
    },
    confirm() {
      this.data.identification = this.$store.state.client.client.identificacion
      this.response = {}
      if (
        this.data.contrato === ''
        || this.data.contrato === null
        || !this.data.contrato
      ) {
        // this.openActualizacionDatos()
        this.$bvModal.show('modal-confirm-process')
        this.response.message = 'Debes diligenciar un contrato'
        this.response.variant = 'danger'
        return
      }
      if (
        this.phone === ''
        || this.phone === null
        || !this.phone
        || !this.email
        || this.email === ''
        || this.email === null
      ) {
        if (!this.contratos.length > 0) {
          if (this.$route.params.mode === 'kiosko') {
            this.$bvModal.show('modal-confirm-process')
            this.response.message = 'Por favor acercarse a la ventanilla para validar sus datos.'
            this.response.variant = 'warning'
          }
          if (this.$route.params.mode === 'portal') {
            this.$bvModal.show('modal-confirm-process')
            this.response.message = 'Debe actualizar tu información para solicitar la instalación del medidor, registrate para actulizar datos.'
            this.response.variant = 'warning'
            this.response.okAccion = true
          }
        } else {
          this.openActualizacionDatos()
        }

        return
      }
      this.loading = true
      this.disabledButton = true
      this.data.idService = this.idService
      this.data.phone = this.phone
      this.data.email = this.email
      //  this.data.observacion = `El usuario desea pagar la instalación del medidor en ${this.cuotaValue} cuotas`
      this.data.observacion = this.cuotaValue
      this.$bvModal.show('modal-confirm-process')
      this.sendGetProcedure(this.data)
        .then(res => {
          if (res.data.code === 0) {
            this.response = res.data
            this.response.message = res.data.msg || 'Su tramite ha sido enviado'
            this.response.base64 = `data:application/pdf;base64,${res.data.object}` || null
            this.response.variant = 'success'
          } else {
            this.response = res.data
            this.response.message = res.data.msg || 'Error al procesar su tramite'
            this.response.variant = 'danger'
          }
          this.data = {}
          this.phone = ''
          this.email = ''
          this.cuotaValue = ''
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.disabledButton = false
        })
    },

    async sendGetProcedure(formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/app/procedure/instalacionServicio', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>
